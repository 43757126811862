import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Button from "@/common/components/button";
import InfoIcon from "@mui/icons-material/Info";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import { SyntheticEvent } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import { Drawer, Typography } from "@mui/material";
import { useAppDispatch } from "@/redux/store";
import { inviteTeamUser } from "@/redux/reducers/settings/team";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { Roles } from "@/common/constants/roles";
import { useAuth0 } from "@auth0/auth0-react";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";

export default function AddUserForm(props: any) {
  const { toggleDrawer, isOpen } = props;
  const { control, handleSubmit, clearErrors, getValues } = useForm();
  const { informativeConfirm } = useConfirmModal();
  const { user } = useAuth0();
  const role = user?.team_user_role;
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<any> = (data) => {
    if (!data) return;
    dispatch(
      inviteTeamUser({
        email: data.email,
        fullName: data.firstName + " " + data.lastName,
        role: data.role.value,
      }),
    );
    informativeConfirm({
      textDiv: (
        <>
          <Typography variant="body2">Invitation sent succesfully!</Typography>
        </>
      ),
      confirmationText: "Thanks",
    }).then(() => {
      toggleDrawer(false);
    });

    try {
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
  const NewUser = () => (
    <>
      <form
        onSubmit={(event: SyntheticEvent) => {
          event.preventDefault();
          clearErrors();
          onSubmit(getValues());
        }}
      >
        <Box sx={{ height: "calc(100vh - 80px)", overflow: "scroll" }}>
          <DrawerLayout customWidth="500px" title="Invite User" testid="inviteUser" closeDrawer={toggleDrawer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="First Name" />
                      <Input {...field} type="text" placeholder="First Name" />
                    </>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="Last Name" />
                      <Input {...field} type="text" placeholder="Last Name" />
                    </>
                  )}
                />
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="Role" />
                      <Dropdown
                        isSearchable
                        label="Role"
                        placeholder="Select a role"
                        options={Roles?.map((role) => ({
                          label: role.roleName,
                          value: role.roleCode,
                        }))}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="Email" />
                      <Input {...field} type="text" placeholder="Email" />
                    </>
                  )}
                />
              </FormControl>
              <Box>
                <Typography variant="body2">
                  <InfoIcon sx={{ verticalAlign: "middle", marginRight: "5px" }} color="info" />
                  An invitation will be sent by email. User must accept the invitation in order to create their own
                  password.
                </Typography>
              </Box>
            </Box>
          </DrawerLayout>
        </Box>
        <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
          <Button
            variant="secondary"
            sx={{ flex: 1, borderColor: "gray", color: "gray" }}
            onClick={toggleDrawer(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" sx={{ flex: 1 }} onClick={handleSubmit(onSubmit)}>
            Invite User
          </Button>
        </Stack>
      </form>
    </>
  );

  return (
    <>
      {role === KnTeamUserRoleUserRoleEnum.Representative ? null : (
        <Button variant="primary" onClick={toggleDrawer(true)}>
          Invite User
        </Button>
      )}

      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        <NewUser />
      </Drawer>
    </>
  );
}
