import { Box, Button, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { dateTimeFormat } from "@/common/functions/dateFormat";
import { DataImportJob } from "@/types/data-import";
import DataImportChip from "./chips";
import { SortAscending, SortDescending } from "heroicons-react";
import { DataImportFiltersProps } from "../filters";
import TableActionButton from "./ActionButton";

const columnHelper = createColumnHelper<DataImportJob>();

export const DataImportColumns = ({ filters, setFilters }: DataImportFiltersProps) => {
  const updateSort = () => {
    if (filters?.sortDirection === "asc") {
      setFilters((previousFilters) => ({ ...previousFilters, sortDirection: "desc" }));

      return;
    }

    setFilters((previousFilters) => ({ ...previousFilters, sortDirection: "asc" }));
  };

  return [
    columnHelper.accessor("importJobName", {
      header: () => (
        <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
          <Typography variant="body3" fontWeight="600">
            IMPORT NAME
          </Typography>
        </div>
      ),
      cell: (info) => {
        return (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
            <Typography variant="body2">{info.getValue() || ""}</Typography>
          </div>
        );
      },
    }),

    columnHelper.accessor("importJobType", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          OBJECT
        </Typography>
      ),
      cell: (info) => <DataImportChip status={info.getValue() || ""} />,
    }),

    columnHelper.accessor("createdDocumentResultCount", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          NEW RECORDS
        </Typography>
      ),
      cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
    }),

    columnHelper.accessor("updatedDocumentResultCount", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          UPDATED RECORDS
        </Typography>
      ),
      cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
    }),

    columnHelper.accessor("errorDocumentResultCount", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          ERROR COUNT
        </Typography>
      ),
      cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
    }),

    columnHelper.accessor("createdAt", {
      header: () => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body3" fontWeight="600">
            IMPORTED ON
          </Typography>
          <Button variant="text" onClick={() => updateSort()}>
            {filters?.sortDirection === "asc" ? (
              <SortAscending width={24} color="gray" />
            ) : (
              <SortDescending width={24} color="gray" />
            )}
          </Button>
        </Box>
      ),
      cell: (info) => {
        const seconds = info.getValue()?.seconds || 0;
        const nanos = info.getValue()?.nanos || 0;
        const formatedDate = dateTimeFormat(seconds, nanos);

        return <Typography variant="body2">{formatedDate}</Typography>;
      },
    }),

    // columnHelper.display({
    //   id: "actions",
    //   header: () => (
    //     <Typography variant="body3" fontWeight="600">
    //       Actions
    //     </Typography>
    //   ),
    //   cell: (props) => <TableActionButton params={props} />,
    // }),
  ];
};
