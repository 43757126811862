import Button from "@/common/components/button";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import PageHeader from "@/common/components/page-header/PageHeader";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import routes from "@/common/constants/routes";
import { getTriggers } from "@/redux/reducers/triggers/slices/listTriggers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TriggersFilters from "./filters/TriggersFilters";
import { TriggerColumns } from "./table/columns";
import { LookupTriggersRequestActionTypeEnum, LookupTriggersRequestEventTypeEnum } from "@/services/generated";

export type TriggersFiltersInput = {
  createdBy?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  triggerName?: string;
  pipelineId?: string;
  deal?: string;
  eventType?: LookupTriggersRequestEventTypeEnum;
  actionType?: LookupTriggersRequestActionTypeEnum;
  status?: string;
};

export default function TriggersPage() {
  const { t } = useTranslation();
  const triggers = useAppSelector(({ trigger }) => trigger?.triggers) || [];
  const totalCount = useAppSelector(({ trigger }) => trigger?.totalCount);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<TriggersFiltersInput>({});

  const { loadingTriggers } = useAppSelector(({ trigger }) => trigger);

  const { onPaginationChange, pagination } = usePagination();

  useEffect(() => {
    dispatch(
      getTriggers({
        createdBy: filters?.createdBy,
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sortBy: filters?.sortBy,
        sortDirection: filters?.sortDirection,
        name: filters?.triggerName,
        // dealId: filters?.deal,
        // ???: filters?.pipelineId,
        eventType: filters?.eventType ? filters?.eventType : undefined,
        actionType: filters?.actionType ? filters?.actionType : undefined,
        isActive: !filters?.status ? undefined : filters?.status === "active",
      }),
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters]);

  const table = useReactTable<any>({
    data: triggers || [],
    columns: TriggerColumns({ filters, setFilters }),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: totalCount,

    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  const openUpdateTrigger = (id: string) => {
    navigate(routes.triggers.update.with({ triggerId: id }));
  };

  const openTriggerCreate = () => {
    navigate(routes.triggers.create.path);
  };

  const hasFilters = Object.keys(filters).some((key) =>
    key !== "viewType" ? filters[key as keyof TriggersFiltersInput] !== undefined : false,
  );

  return (
    <>
      {loadingTriggers ? (
        <FullLoadingOverlay loading={loadingTriggers} />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <PageHeader
            title={t("triggers.title")}
            actions={
              <Button data-testid="openTaskDrawer" variant="primary" onClick={openTriggerCreate}>
                Create Trigger
              </Button>
            }
            filters={<TriggersFilters filters={filters} setFilters={setFilters} />}
          />

          <TsTable
            table={table}
            handleCellClick={openUpdateTrigger}
            notFoundProps={{
              title: hasFilters ? "No triggers found that match your criteria" : "No triggers found",
              description: hasFilters ? (
                <span>
                  Try a different search parameter or simply <br /> clear all the filters with the button below.
                </span>
              ) : (
                <span>
                  Begin creating a trigger by clicking the <br />
                  <b>"Create Trigger"</b> button above.
                </span>
              ),

              Icon: RocketLaunchIcon,
            }}
          />
        </Box>
      )}
    </>
  );
}
