import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DrawerLayout from "@/layouts/DrawerLayout";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import {
  closeEditDrawer,
  createProspect,
  setCurrentProspect,
  updateProspect,
} from "@/redux/reducers/prospects/slices/listProspects";
import {
  ProspectJobTitleOptions,
  ProspectAddressOptions,
  ProspectLeadTypeOptions,
} from "@/redux/reducers/prospects/slices/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnContactRequest, KnConsentSource, LeadSourceTypeEnum } from "@/services/generated";
import { useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import PhoneInput from "@/common/components/phone-input";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import Box from "@mui/material/Box";
import Checkbox from "@/common/components/checkbox/Checkbox";
import { Consent } from "@/redux/reducers/prospects/slices/types";

const schema = Yup.object({
  firstName: Yup.string().label("First Name").required(),
  lastName: Yup.string().label("Last Name").required(),
  email: Yup.string(), //.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email"),
  phoneNumber: Yup.string().required(),
  companyId: Yup.string(),
  jobTitle: Yup.string(),
  address: Yup.object({
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    postal_code: Yup.string(),
  }),
  ownerUserId: Yup.string(),
  leadType: Yup.string(),
  consent: Yup.boolean(),
});

export type ContactForm = Yup.InferType<typeof schema>;

const CONSENT_SOURCE_LVL_2: Consent = {
  consent: {
    level: "lvl_2",
    // TODO: Default ACTIVE in Data Import.
    status: "ACTIVE",
  },
};

const CONSENT_SOURCE_NONE: Consent = {
  consent: {
    level: "none",
    status: "ACTIVE",
  },
};

export default function EditProspectForm() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const companies = useAppSelector(({ companies }) => companies.listCompanies.companies);

  const { control, handleSubmit, reset } = useForm<ContactForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const consent = !!Object.values(currentProspect?.consentSources || {}).find(
      (v: KnConsentSource) => v.level === "lvl_2" && v.status === "active",
    );

    reset({
      firstName: currentProspect?.firstName,
      lastName: currentProspect?.lastName,
      email: currentProspect?.email,
      phoneNumber: currentProspect?.phoneNumber,
      companyId: currentProspect?.companyId || "",
      jobTitle: currentProspect?.jobTitle,
      address: {
        city: currentProspect?.address?.city || "",
        state: currentProspect?.address?.state || "",
        country: currentProspect?.address?.country || "",
        postal_code: currentProspect?.address?.postal_code || "",
      },
      ownerUserId: currentProspect?.ownerUserId,
      leadType: currentProspect?.leadType || "",
      consent: consent,
    });
  }, [reset, currentProspect]);

  const assigneeOptions = useMemo(() => {
    return (
      teamUsers?.map((user) => {
        return {
          value: user.user_id,
          label: `${user.firstName} ${user.lastName}`,
        };
      }) || []
    );
  }, [teamUsers]);

  const companyOptions = useMemo(() => {
    return (
      companies?.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      }) || []
    );
  }, [companies]);

  const onSubmit: SubmitHandler<ContactForm> = (data) => {
    if (!data) return;

    const prospect: KnContactRequest = {
      // TODO reorder
      id: currentProspect?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      jobTitle: data.jobTitle,
      address: data.address,
      ownerUserId: data.ownerUserId,
      leadType: data.leadType,
      consentSources: data.consent ? CONSENT_SOURCE_LVL_2 : CONSENT_SOURCE_NONE,
      // SYSTEM
      leadSource: LeadSourceTypeEnum.Ui,
    };

    if (currentProspect?.id) {
      dispatch(updateProspect(prospect));
    } else {
      dispatch(createProspect(prospect));
    }
    closeDrawer();
  };

  const onError: SubmitErrorHandler<ContactForm> = (errors) => {
    console.log(errors);
  };

  const closeDrawer = () => {
    dispatch(setCurrentProspect(null));
    dispatch(closeEditDrawer());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <DrawerLayout
        customWidth="500px"
        title={t("prospects.createProspectTitle")}
        testid="newProspectForm"
        closeDrawer={closeDrawer}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel aria-required label="First Name" />
                  <Input {...field} type="text" placeholder="John" error={fieldState?.error?.message} />
                </FormControl>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel aria-required label="Last Name" />
                  <Input {...field} type="text" placeholder="Doe" error={fieldState?.error?.message} />
                </FormControl>
              )}
            />
          </Box>

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Email" />
                <Input {...field} type="text" placeholder="johndoe@email.com" error={fieldState?.error?.message} />
              </FormControl>
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Phone" />
                <PhoneInput
                  value={field.value}
                  onChange={(phone) => {
                    const phoneValue = phone.startsWith("+") ? phone : `+${phone}`;
                    field.onChange(phoneValue);
                  }}
                  country={"us"}
                  error={fieldState?.error?.message}
                />
              </FormControl>
            )}
          />

          <Controller
            name="companyId"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Company" />
                <Dropdown
                  isSearchable
                  label="Company"
                  options={companyOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                  error={fieldState?.error?.message}
                />
              </FormControl>
            )}
          />

          <Controller
            name="jobTitle"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Job Title" />
                <Dropdown
                  isSearchable
                  label="Job Title"
                  options={ProspectJobTitleOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Location" />
                <Dropdown
                  isSearchable
                  label="Location"
                  options={ProspectAddressOptions}
                  value={field.value as any}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="ownerUserId"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Assignee" />
                <Dropdown
                  isSearchable
                  label="Assignee"
                  options={assigneeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="leadType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Lead Type" />
                <Dropdown
                  isSearchable
                  label="Lead Type"
                  options={ProspectLeadTypeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="consent"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Consent" />
                <Checkbox
                  {...field}
                  error={fieldState.error?.message}
                  checked={!!field.value}
                  label="By checking this box, I acknowledge that I have received the required consent for communication to these contacts."
                />
              </FormControl>
            )}
          />
        </Box>
        <DrawerButtonsLayout>
          <Button
            data-testid="closeTaskDrawer"
            variant="secondary"
            onClick={closeDrawer}
            type="button"
            sx={{ width: "100%" }}
          >
            {t("tasks.cancel")}
          </Button>

          <Button type="submit" variant="primary" sx={{ width: "100%" }}>
            Save
          </Button>
        </DrawerButtonsLayout>
      </DrawerLayout>
    </form>
  );
}
