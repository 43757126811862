import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/store";
import { useAppSelector } from "@/redux/store";
import { useMemo } from "react";
import { getDeals } from "@/redux/reducers/dealFlow/slices/listDeals";
import { getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import { LookupTriggersRequestActionTypeEnum, LookupTriggersRequestEventTypeEnum } from "@/services/generated";
import { TriggersFiltersInput } from "@/pages/triggers";
import { debounce } from "lodash";

export type TriggersFilterProps = {
  filters: TriggersFiltersInput;
  setFilters: (filters: TriggersFiltersInput | ((prevFilters: TriggersFiltersInput) => TriggersFiltersInput)) => void;
};

const eventTypeOptions = [
  {
    label: "New Contact",
    value: LookupTriggersRequestEventTypeEnum.NewContact,
  },
  {
    label: "Prospect Engagement",
    value: LookupTriggersRequestEventTypeEnum.ProspectEngagement,
  },
  {
    label: "Meeting State Change",
    value: LookupTriggersRequestEventTypeEnum.MeetingStateChange,
  },
  {
    label: "Deal Flow State Change",
    value: LookupTriggersRequestEventTypeEnum.DealFlowStateChange,
  },
  {
    label: "Deal Assignee Change",
    value: LookupTriggersRequestEventTypeEnum.DealAssigneeChange,
  },
];

const actionTypeOptions = [
  {
    label: "Cancel Sequence",
    value: LookupTriggersRequestActionTypeEnum.CancelSequence,
  },
  {
    label: "Create Deal",
    value: LookupTriggersRequestActionTypeEnum.CreateDeal,
  },
  {
    label: "Create Task",
    value: LookupTriggersRequestActionTypeEnum.CreateTask,
  },
  {
    label: "Enroll Sequence",
    value: LookupTriggersRequestActionTypeEnum.EnrollSequence,
  },
  {
    label: "Send Email",
    value: LookupTriggersRequestActionTypeEnum.EnrollSequence,
  },
  {
    label: "Set Deal Flow Stage",
    value: LookupTriggersRequestActionTypeEnum.SetDealFlowStage,
  },
];

export default function TriggersFilters({ filters, setFilters }: TriggersFilterProps) {
  const dispatch = useAppDispatch();
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const [nameFilter, setNameFilter] = useState("");

  useEffect(() => {
    if (pipelines === undefined) {
      dispatch(getPipelines());
    }
  }, [pipelines, dispatch]);

  useEffect(() => {
    if (deals === undefined && pipelines) dispatch(getDeals(""));
  }, [dispatch, deals]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, triggerName: value }));
      }, 500),
    [setFilters],
  );

  const pipelineOptions = useMemo(
    () => (pipelines || []).map((pipeline) => ({ value: pipeline.id, label: pipeline.name })),
    [pipelines],
  );

  useEffect(() => {
    debouncedSetFilters(nameFilter);
  }, [nameFilter, debouncedSetFilters]);

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Searchbar
          sx={{ flexGrow: 1 }}
          placeholder="Search by Name"
          value={nameFilter}
          onChange={({ target: { value } }: any) => {
            setNameFilter(value);
          }}
        />

        {/*<Box>*/}
        {/*  <Dropdown*/}
        {/*    label="Select Pipeline"*/}
        {/*    placeholder="Select Pipeline"*/}
        {/*    value={filters.pipelineId}*/}
        {/*    options={pipelineOptions}*/}
        {/*    onChange={(option) => {*/}
        {/*      setFilters({ ...filters, pipelineId: option.value });*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Box>*/}

        <Box>
          <Dropdown
            label="Event Type"
            placeholder="Event Type"
            value={filters.eventType}
            options={eventTypeOptions}
            onChange={(option) => {
              setFilters({ ...filters, eventType: option.value });
            }}
          />
        </Box>

        <Box>
          <Dropdown
            label="Action Type"
            placeholder="Action Type"
            value={filters.actionType}
            options={actionTypeOptions}
            onChange={(option) => {
              setFilters({ ...filters, actionType: option.value });
            }}
          />
        </Box>

        <Box>
          <Dropdown
            label="Status"
            placeholder="Status"
            value={filters.status}
            options={[
              {
                label: "Active",
                value: "active",
              },
              {
                label: "Inactive",
                value: "inactive",
              },
            ]}
            onChange={(option) => {
              setFilters({ ...filters, status: option.value });
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
