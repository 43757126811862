import { Box, Divider } from "@mui/material";
import BasicInformation from "@/pages/settings/brand-registration/BasicInformation";
import Location from "@/pages/settings/brand-registration/Location";
import Contact from "@/pages/settings/brand-registration/Contact";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createBrandRegistration } from "@/redux/reducers/settings/brand-registration/create";
import {
  BrandRegistrationFormValues,
  mapBrandRegistrationRequest,
} from "@/redux/reducers/settings/brand-registration/mapper";
import { useAuth0 } from "@auth0/auth0-react";

const schema = Yup.object({
  businessName: Yup.string().required("Business name is required"),
  businessType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("Business type is required"),
  taxId: Yup.string().required("Tax ID is required"),
  businessUrl: Yup.string().required("Business URL is required"),
  businessSupportEmail: Yup.string().required("Business support email is required"),
  businessIndustry: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("Business industry is required"),
  contactFirstName: Yup.string().required("First name is required"),
  contactLastName: Yup.string().required("Last name is required"),
  contactEmail: Yup.string().required("Email is required"),
  contactPhone: Yup.string().required("Phone number is required"),
  contactJobTitle: Yup.string().required("Job title is required"),
  businessAddress: Yup.string().required("Business address is required"),
  businessAddress2: Yup.string(),
  businessCity: Yup.string(),
  businessState: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("Business state is required"),
  businessPostalCode: Yup.string(),
  businessCountry: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required("Business country is required"),
}).required();

export default function BrandRegistrationPage() {
  const dispatch = useAppDispatch();
  const brandRegistration = useAppSelector(({ settings }) => settings.brandRegistration.brandRegistration);
  const { user } = useAuth0();

  const methods = useForm<BrandRegistrationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: brandRegistration,
  });

  const onSubmit = (values: BrandRegistrationFormValues) => {
    const mappedValues = mapBrandRegistrationRequest(values);
    dispatch(
      createBrandRegistration({
        ...mappedValues,
        owner_team_id: user?.team_id,
      }),
    );
  };

  const onError: SubmitErrorHandler<BrandRegistrationFormValues> = (errors) => {
    // eslint-disable-next-line no-console
    console.log(errors);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Box sx={{ display: "grid", gap: "32px" }}>
          <BasicInformation control={methods.control} />
          <Location control={methods.control} />
          <Contact control={methods.control} />
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <Button type="submit" variant="secondary">
              Submit Brand Registration for Review
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
