import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Trigger } from "./types";
import { createTrigger } from "./createTrigger";
import { updateTrigger } from "./updateTrigger";
import { ListByEventTypeEventEnum, LookupTriggersRequest } from "@/services/generated";

type State = {
  triggers?: Trigger[];
  currentTrigger: Trigger | null;
  loading: boolean;
  error: string | null;
  loadingTriggers: boolean;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  triggerActions: string[];
  triggerActionsPending: boolean;
  createTriggerPending?: boolean;
  updateTriggerPending?: boolean;
};

const initialState: State = {
  triggers: [],
  currentTrigger: null,
  loading: false,
  error: null,
  loadingTriggers: true,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10,
  triggerActions: [],
  triggerActionsPending: false,
  createTriggerPending: false,
  updateTriggerPending: false,
};

type Filters = {
  createdBy: string | undefined;
  pageNumber: number | undefined;
  pageSize: number | undefined;
  sortBy: string | undefined;
  sortDirection: string | undefined;
  triggerName: string | undefined;
  active: boolean | undefined;
  dealId: string | undefined;
};

export const setCurrentTrigger = createAsyncThunk("triggers/get", async (id: string) => {
  try {
    const response = await Services.Triggers.getTrigger(id);
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
});

export const handleDelete = createAsyncThunk("triggers/delete", async (id: string) => {
  try {
    await Services.Triggers.deleteTrigger(id);
    return id;
  } catch (error) {
    return error;
  }
});

export const getTriggers = createAsyncThunk("triggers/getTriggers", async (request: LookupTriggersRequest) => {
  try {
    return await Services.Triggers.listTrigger(request);
  } catch (error: any) {
    return error;
  }
});

export const getActionsByEventType = createAsyncThunk(
  "triggers/getActionsByEventType",
  async (eventType: ListByEventTypeEventEnum) => {
    try {
      const response = await Services.TriggerActions.listByEventType(eventType);
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

const listTriggersSlice = createSlice({
  name: "listTriggers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getActionsByEventType.pending, (state) => {
        state.triggerActions = [];
        state.triggerActionsPending = true;
      })
      .addCase(getActionsByEventType.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.triggerActions = payload.data;
          state.triggerActionsPending = false;
        }
      })
      .addCase(getActionsByEventType.rejected, (state) => {
        state.triggerActions = [];
        state.triggerActionsPending = false;
      })
      .addCase(getTriggers.fulfilled, (state, { payload }) => {
        if (payload.data?.data) {
          state.triggers = payload.data.data || [];
          state.loadingTriggers = false;
          state.totalCount = payload.data.total || 0;
          state.pageNumber = payload.data.pageNumber || 0;
          state.pageSize = payload.data.pageSize || 0;
        }
      })
      .addCase(getTriggers.rejected, (state, { error }) => {
        // state.error = error.message;
        state.loadingTriggers = false;
        state.triggers = [];
      })

      .addCase(createTrigger.pending, (state, { payload }) => {
        state.createTriggerPending = true;
      })
      .addCase(createTrigger.fulfilled, (state, { payload }) => {
        if (payload) {
          state.createTriggerPending = false;
          state.triggers = [payload, ...(state.triggers ?? [])];
        }
      })

      .addCase(updateTrigger.pending, (state, { payload }) => {
        state.updateTriggerPending = true;
      })
      .addCase(updateTrigger.fulfilled, (state, { payload }) => {
        state.updateTriggerPending = false;
        if (payload) {
          state.triggers = state.triggers?.map((triggers) => (triggers.id === payload.id ? payload : triggers)) as any;
        }
      })

      .addCase(handleDelete.fulfilled, (state, { payload }) => {
        state.triggers = state.triggers?.filter((task) => task.id !== payload);
      })

      .addCase(setCurrentTrigger.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentTrigger = action.payload.data;
        }
      });
  },
  reducers: {},
});

export const {} = listTriggersSlice.actions;

export default listTriggersSlice.reducer;
