import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TaskRequest } from "@/services/generated";
import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import { createTask } from "./createTask"; // Importar la acción createTask
import { updateTask } from "./updateTask";
import { success } from "@/lib/toast";

export interface Task extends TaskRequest {
  id?: string;
  assignee?: {
    id?: string;
    name?: string;
  };
  prospect?: {
    id?: string;
    name?: string;
  };
  deal?: any;
  dueTime?: string;
}

type State = {
  viewDrawerIsOpen: boolean;
  smsEmailDrawerIsOpen: boolean;
  editDrawerIsOpen: boolean;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  assigneeDrawerIsOpen?: boolean;

  // Get current task
  currentTask: Task | null;
  loadingCurrentTask?: boolean;
  currentTaskId?: string;

  // Get tasks
  tasks?: Task[];
  loadingTasks?: boolean;

  // Update task
  pendingUpdateTask?: boolean;
  errorUpdateTask?: string;

  // Create task
  pendingCreateTask?: boolean;
  createTaskDrawerIsOpen: boolean;
  errorCreateTask?: string;
};

const initialState: State = {
  currentTask: null,
  tasks: [],
  loadingTasks: true,
  viewDrawerIsOpen: false,
  smsEmailDrawerIsOpen: false,
  editDrawerIsOpen: false,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10,
  assigneeDrawerIsOpen: false,
  pendingCreateTask: false,
  pendingUpdateTask: false,
  loadingCurrentTask: false,
  createTaskDrawerIsOpen: false,
};

type Filters = {
  pageNumber?: number;
  pageSize?: number;
  assigneeId?: string;
  type?: TaskRequestTypeEnum;
  status?: TaskRequestStatusEnum;
  dealId?: string;
  dueDateStart?: string;
  dueDateEnd?: string;
  sortBy?: string;
  sortDirection?: string;
  prospectName?: string;
  createTaskDrawerIsOpen?: boolean;
};

export const setCurrentTask = createAsyncThunk("tasks/get", async (id: string) => {
  try {
    const response = await Services.Tasks.get(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const getTasks = createAsyncThunk("tasks/getTasks", async (filters?: Filters) => {
  try {
    const response = await Services.Tasks.findAll({
      page: filters?.pageNumber ?? 0,
      pageSize: filters?.pageSize ?? 10,
      assigneeId: filters?.assigneeId,
      type: filters?.type?.length ? filters?.type : undefined,
      status: filters?.status?.length ? filters?.status : undefined,
      dealId: filters?.dealId?.length ? filters?.dealId : undefined,
      dueDateStart: filters?.dueDateStart?.length ? filters?.dueDateStart : undefined,
      dueDateEnd: filters?.dueDateEnd?.length ? filters?.dueDateEnd : undefined,
      sortBy: filters?.sortBy ?? "createdAt",
      sortDirection: filters?.sortDirection ?? "desc",
      prospectName: filters?.prospectName ?? undefined,
    });

    return response;
  } catch (error) {
    return error;
  }
});

export const deleteTask = createAsyncThunk("tasks/deleteTask", async (id: string) => {
  await Services.Tasks._delete(id);
  return id;
});

const listTaskSlice = createSlice({
  name: "listTask",
  initialState,
  extraReducers: (builder) => {
    builder

      // GET TASKS
      .addCase(getTasks.fulfilled, (state, { payload }) => {
        if (payload.data?.data) {
          state.loadingTasks = false;
          state.totalCount = payload.data.total || 0;
          state.pageNumber = payload.data.pageNumber || 0;
          state.pageSize = payload.data.pageSize || 0;
          state.tasks = payload.data.data || [];
        }
      })

      // DELETE TASK
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        state.tasks = state.tasks?.filter((task) => task.id !== payload);
        success("Task deleted successfully");
      })

      // UPDATE TASK
      .addCase(updateTask.rejected, (state) => {
        state.pendingUpdateTask = false;
        state.errorUpdateTask = "Failed to update task";
      })
      .addCase(updateTask.pending, (state) => {
        state.pendingUpdateTask = true;
      })
      .addCase(updateTask.fulfilled, (state, { payload }) => {
        if (payload) {
          state.tasks = state.tasks?.map((task) => (task.id === payload.id ? payload : task)) as any;
          state.pendingUpdateTask = false;
          state.editDrawerIsOpen = false;
          state.viewDrawerIsOpen = false;
        }
      })

      // CREATE TASK
      .addCase(createTask.pending, (state) => {
        state.pendingCreateTask = true;
      })
      .addCase(createTask.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.tasks = [payload, ...(state.tasks ?? [])];
          state.pendingCreateTask = false;
          state.createTaskDrawerIsOpen = false;
        }
      })
      .addCase(createTask.rejected, (state) => {
        state.pendingCreateTask = false;
        state.errorCreateTask = "Failed to create task";
      })

      // SET CURRENT TASK
      .addCase(setCurrentTask.pending, (state) => {
        state.loadingCurrentTask = true;
      })
      .addCase(setCurrentTask.fulfilled, (state, action) => {
        if (action.payload) {
          state.loadingCurrentTask = false;
          state.currentTask = action.payload.data;
        }
      });
  },
  reducers: {
    openViewTaskDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewTaskDrawer: (state) => {
      state.viewDrawerIsOpen = false;
      state.currentTask = null;
    },
    smsEmailViewDrawerToggle: (state) => {
      state.smsEmailDrawerIsOpen = !state.smsEmailDrawerIsOpen;
    },
    openCreateTaskDrawer: (state) => {
      state.createTaskDrawerIsOpen = true;
      state.currentTask = null;
    },
    closeCreateTaskDrawer: (state) => {
      state.createTaskDrawerIsOpen = false;
      state.currentTask = null;
    },
    openEditTaskDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditTaskDrawer: (state) => {
      state.editDrawerIsOpen = false;
      state.currentTask = null;
    },
    openAssigneeDrawer: (state) => {
      state.assigneeDrawerIsOpen = true;
    },
    closeAssigneeDrawer: (state) => {
      state.assigneeDrawerIsOpen = false;
      state.currentTask = null;
    },

    nextTask: (state) => {
      if (!state.tasks) {
        return;
      }
      const { tasks, currentTask } = state;
      const nextTaskPosition = tasks.findIndex((task) => task.id === currentTask?.id) + 1;
      const nextTask = tasks[nextTaskPosition];
      if (nextTaskPosition > tasks.length - 1) return;
      state.currentTask = nextTask;
    },
  },
});

export const {
  nextTask,
  openViewTaskDrawer,
  closeViewTaskDrawer,
  openEditTaskDrawer,
  closeEditTaskDrawer,
  smsEmailViewDrawerToggle,
  openAssigneeDrawer,
  closeAssigneeDrawer,
  openCreateTaskDrawer,
  closeCreateTaskDrawer,
} = listTaskSlice.actions;

export default listTaskSlice.reducer;
