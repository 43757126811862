import { Typography } from "@mui/material";
import TableActionButton from "./ActionButton";
import { createColumnHelper, CellContext } from "@tanstack/react-table";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import TableDate from "@/common/components/table-date";
import ConsentStatusChip from "@/common/components/status-chip/ConsentStatusChip";
import React from "react";

const columnHelper = createColumnHelper<Prospect>();

export const ProspectColumns = [
  columnHelper.accessor("checkbox", {
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          gap: "17px",
          alignItems: "center",
          margin: "0 auto",
          maxWidth: "0px",
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),

    cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex",
            gap: "17px",
            alignItems: "center",
            margin: "0 auto",
            width: "0px",
            maxWidth: "0px",
          }}
        >
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  }),

  columnHelper.accessor("firstName", {
    header: ({ table }: any) => (
      <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
        <Typography variant="body3" fontWeight="600">
          Prospect
        </Typography>
      </div>
    ),

    cell: (info) => {
      const row = info.row;
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
          <Typography variant="body2">{`${info.getValue()}  ${row.original.lastName}`}</Typography>
        </div>
      );
    },
  }),

  columnHelper.accessor("ownerUserFullName", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Assignee
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor((row) => row.companyName, {
    id: "Company",
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Primary Company
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("consent", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Consent
      </Typography>
    ),

    cell: (info) => {
      return <ConsentStatusChip consent={info.getValue() || false} />;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Created Date
      </Typography>
    ),
    cell: (info: CellContext<Prospect, number>) => {
      return <TableDate nanos={info?.getValue()} />;
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Actions
      </Typography>
    ),
    cell: (props) => <TableActionButton params={props} />,
  }),
];
